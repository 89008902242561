<template>
  <section class="dtc-main-section input-container">
    <h4>
      尚在訓練中的養成公費醫事人員請填寫該份表單(共18題)
    </h4>
    <header
      class="dtc-2-column header-dark"
      style="text-align:center;height:30px;"
    >
      <div>問題</div>
      <div>回答</div>
    </header>
    <!-- 1 -->
    <main class="dtc-2-column">
      <div class="number-div">
        <div>1</div>
        <div>
          請問您一開始是如何得知有關成公費醫事人員報的資訊呢?(可複選,至多選擇三項)
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q1"
          :options="q1Opts"
          @change="updateItems('q1')"
        ></b-form-checkbox-group>
        <b-input v-model="q1Other"></b-input>
      </div>
    </main>
    <!-- 2-->
    <main class="dtc-2-column">
      <div class="number-div">
        <div>2</div>
        <div>
          <div>需求評估</div>
          <div>若回到大學時期,您會希望有以下的協助嗎?(請選擇最重要的三項)</div>
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q2"
          :options="q2Opts"
          @change="updateItems('q2')"
        ></b-form-checkbox-group>
        <b-input v-model="q2Other"></b-input>
      </div>
    </main>
    <!-- 3-->
    <main class="dtc-2-column">
      <div class="number-div">
        <div>3</div>
        <div>
          <div>請問您是何時開始了解公費生的權利義務呢?</div>
          <div>
            (因各類醫事科系在校就讀時間皆不同,故僅須就實際就讀年份做填答)
          </div>
        </div>
      </div>
      <div>
        <b-form-radio-group
          stacked
          v-model="q3"
          :options="q3Opts"
        ></b-form-radio-group>
      </div>
    </main>
    <!-- 4-->
    <main class="dtc-2-column">
      <div class="number-div">
        <div>4</div>
        <div>
          <span>本題僅需就讀醫學系者填寫,非醫學系者不必填寫:</span>
          <span
            >您認為在專業科別的選擇上主要是受到哪些因素影響呢?(請選擇最重要的三項)</span
          >
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q4"
          :options="q4Opts"
          @change="updateItems('q4')"
        ></b-form-checkbox-group>
      </div>
    </main>
    <!-- 5-->
    <main class="dtc-2-column">
      <div class="number-div">
        <div>5</div>
        <div>
          <span>
            您是否曾因為公費身份被師長、學校 職 員、同儕朋友另眼相待呢
            無論是羨慕 或忌妒、正向或負向的對待</span
          >
        </div>
      </div>
      <div>
        <b-form-radio-group
          stacked
          v-model="q5"
          :options="q5Opts"
        ></b-form-radio-group>
      </div>
    </main>
    <!-- 6-->
    <main class="dtc-2-column">
      <div class="number-div">
        <div>6</div>
        <div>
          <span>您是否不希望同學、學長姐、老師們知 道您的公費身份呢?</span>
        </div>
      </div>
      <div>
        <b-form-radio-group
          stacked
          v-model="q6"
          :options="q6Opts"
        ></b-form-radio-group>
      </div>
    </main>
    <!--7 mask-->
    <main class="dtc-2-column">
      <div class="number-div">
        <div>7</div>
        <div>
          <span>請問您在課業上或是 訓練上，是否會發 現跟不上 其他同學呢?</span>
        </div>
      </div>
      <div class="dtc-2-rows">
        <div>
          <span>課業上</span>
          <b-form-radio-group
            stacked
            v-model="q71"
            :options="q7Opts"
          ></b-form-radio-group>
        </div>
        <div>
          <span>在pgy時期的訓練上</span>
          <b-form-radio-group
            stacked
            v-model="q72"
            :options="q7Opts"
          ></b-form-radio-group>
        </div>
      </div>
    </main>
    <!--8 mask-->
    <main class="dtc-2-column">
      <div class="number-div">
        <div>8</div>
        <div>
          <span
            >如果曾考慮過放棄公費身份，請問有哪些原因呢?(請選擇最重要的三項)</span
          >
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q8"
          :options="q8Opts"
          @change="updateItems('q8')"
        ></b-form-checkbox-group>
        <b-input v-model="q8Other"></b-input>
      </div>
    </main>
    <!--9 mask-->
    <main class="dtc-2-column">
      <div class="number-div">
        <div>9</div>
        <div><span>在訓練期間，您是否曾經想過以下的問題呢?</span></div>
      </div>
      <div class="dtc-3-rows">
        <div>
          <span>您是否有思考過未來返鄉服務可能要過的生活型態或品質呢?</span>
          <b-form-radio-group
            stacked
            v-model="q91"
            :options="q7Opts"
          ></b-form-radio-group>
        </div>
        <div>
          <span
            >您是否有思考過未來返鄉服務可能會面對的當地居民生活型態或特色呢?</span
          >
          <b-form-radio-group
            stacked
            v-model="q92"
            :options="q7Opts"
          ></b-form-radio-group>
        </div>
        <div>
          <span>您是否有思考過未來返鄉服務可能會面對的健康議題呢?</span>
          <b-form-radio-group
            stacked
            v-model="q93"
            :options="q7Opts"
          ></b-form-radio-group>
        </div>
      </div>
    </main>
    <!--10-->
    <main class="dtc-2-column">
      <div class="number-div">
        <div>10</div>
        <div>
          <span
            >遇到跟公費權利義務相關問題時，您會尋求誰的幫助呢?(請選擇最重要的三項)</span
          >
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q10"
          :options="q10Opts"
          @change="updateItems('q10')"
        ></b-form-checkbox-group>
      </div>
    </main>

    <main class="dtc-2-column">
      <div class="number-div">
        <div>11</div>
        <div>
          <span
            >若訓練期間有養成公費生的相關組織，您認為它帶給您的是什麼樣的幫助
            呢?(請選擇最重要的三項)</span
          >
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q11"
          :options="q11Opts"
          @change="updateItems('q11')"
        ></b-form-checkbox-group>
        <b-input v-model="q11Other"></b-input>
      </div>
    </main>

    <main class="dtc-2-column">
      <div class="number-div">
        <div>12</div>
        <div>
          <div>需求評估</div>
          <div>訓練 期間，您會希望有以下哪些協助呢?(請選擇最重要的三項)</div>
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q12"
          :options="q12Opts"
          @change="updateItems('q12')"
        ></b-form-checkbox-group>
        <b-input v-model="q12Other"></b-input>
      </div>
    </main>
    <!--13 mask more-->
    <main class="dtc-2-column">
      <div class="number-div">
        <div>13</div>
        <div>
          <div>見習需求評估:</div>
          <div>您認為「下鄉見實習」在以下哪個階段 舉辦較合適呢?</div>
        </div>
      </div>
      <div class="dtc-4-rows">
        <div>
          <span>大一至大三時期?</span>
          <b-form-radio-group
            stacked
            v-model="q131"
            :options="q13Opts"
          ></b-form-radio-group>
        </div>
        <div>
          <span>大四至大五時期</span>
          <b-form-radio-group
            stacked
            v-model="q132"
            :options="q13Opts"
          ></b-form-radio-group>
        </div>
        <div>
          <span>大六至大七</span>
          <b-form-radio-group
            stacked
            v-model="q133"
            :options="q13Opts"
          ></b-form-radio-group>
        </div>
        <div>
          <span>專訓時期</span>
          <b-form-radio-group
            stacked
            v-model="q134"
            :options="q13Opts"
          ></b-form-radio-group>
        </div>
      </div>
    </main>

    <main class="dtc-2-column">
      <div class="number-div">
        <div>14</div>
        <div>
          <span
            >您是否有過跟公部門反映公費相關問 題 例如 零用金金額、合約內容等 等
            ))，結果是?</span
          >
        </div>
      </div>
      <div>
        <b-form-radio-group
          stacked
          v-model="q14"
          :options="q14Opts"
        ></b-form-radio-group>
      </div>
    </main>
    <!--15 -->
    <main class="dtc-2-column">
      <div class="number-div">
        <div>15</div>
        <div>
          <span
            >未來在尋找服務地點時，您的選擇可能會受到哪些因素影響呢?(請選擇最重要的三項)</span
          >
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q15"
          :options="q15Opts"
          @change="updateItems('q15')"
        ></b-form-checkbox-group>
        <b-input v-model="q15Other"></b-input>
      </div>
    </main>

    <main class="dtc-2-column">
      <div class="number-div">
        <div>16</div>
        <div>
          <span
            >如果您未來的服務地點可能是「衛生
            所」，您對該執業環境的印象是什麼呢?(請選擇最重要的三項)</span
          >
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q16"
          :options="q16Opts"
          @change="updateItems('q16')"
        ></b-form-checkbox-group>
        <b-input v-model="q16Other"></b-input>
      </div>
    </main>

    <main class="dtc-2-column">
      <div class="number-div">
        <div>17</div>
        <div>
          <span
            >如果您未來的服務地點可能是「部立醫
            院」，您對該執業環境的印象是什麼呢?(請選擇最重要的三項)</span
          >
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q17"
          :options="q17Opts"
          @change="updateItems('q17')"
        ></b-form-checkbox-group>
        <b-input v-model="q17Other"></b-input>
      </div>
    </main>

    <main class="dtc-2-column">
      <div class="number-div">
        <div>18</div>
        <div>
          <span>請問您對現有的養成公費政策有沒有任何想法及意見想提出呢?</span>
        </div>
      </div>
      <div>
        <div>簡答:</div>
        <b-textarea
          placeholder="請輸入"
          spellcheck="false"
          maxlength="400"
          no-resize
          style="height:150px;"
          v-model="q18Other"
        ></b-textarea>
      </div>
    </main>

    <b-button
      size="sm"
      variant="success"
      class="mr-2"
      style="display:inline-block;width:100px;height:40px;margin-top:20px;margin-left:750px"
      @click="submitAnswer"
      v-show="!closeBtn"
    >
      <i class="fas fa-save mr-1"></i>儲存問卷
    </b-button>
  </section>
</template>

<script>
import queryString from "query-string";
import { store } from "@/store/global.js";
const leftQuestions = [
  {
    index: 1,
    question:
      "請問您一開始是如何得知有關成公費醫事人員報的資訊呢?(可複選,至多選擇三項)",
  },
];
const q1Opts = [
  "1 高中老師提過相關資訊",
  "2 在高中校園內發現相關招生資訊(例如:海報)",
  "3 父母、親朋好友告知",
  "4 在網路上查到相關資訊",
  "5 在報章雜誌上發現相關資訊",
  "6 其他:",
];
const q2Opts = [
  "1 校內創立養成公費生的相關社團",
  "2 有養成公費生的專責導師",
  "3 有成公費生的課業助教",
  "4 有成公費生的資訊交流平台",
  "5 有成公費生學長姐有實際互動",
  "6 下鄉見習的機會",
  "7 其他:",
];
const q3Opts = [
  "1 大一時",
  "2 大二時",
  "3 大三時",
  "4 大四時",
  "5 大五時",
  "6 大六時",
  "7 大七時",
];
const q4Opts = [
  "1 未來返鄉服務地點的健康需求",
  "2 未來返鄉服務地點的設備水準",
  "3 見實習的直接體認",
  "4 公費生學長姐的建議",
  "5 公費生或重要親友的建議",
  "6 有利未來服務期滿後的職涯發展",
  "7 跟隨自己的興趣",
];
const q5Opts = ["1 每次都會", "2 大部分時會", "3 偶爾會", "4 幾乎不會"];
const q6Opts = ["1 每次都會", "2 大部分時會", "3 偶爾會", "4 幾乎不會"];
const q7Opts = ["1 常常", "2 有時候", "3 不常", "4 幾乎沒有"];

const q8Opts = [
  "1 沒有想過",
  "2 影響親密關係的發展",
  "3 影響未來子女教養規劃",
  "4 未來生活環境不太理想",
  "5 未來職涯規劃受限",
  "6 與自己想學專業不符",
  "7 合約內容與其他類公費生相比太苛刻",
  "8 其他:",
];

const q10Opts = [
  "1 找公費生同學或是學長姐談談",
  "2 找醫院的同仁或老師談談",
  "3 找父母或親友談談",
  "4 找公部門的相關窗口談談",
  "5 找朋友、社團同學談談",
  "6 找網路上的社群朋友談談",
];
const q11Opts = [
  "1 情感的支持(例如心情不好的安慰)",
  "2 提供訊息或建議",
  "3 物質的支持(例如提供歷屆考古題)",
  "4 歸屬感",
  "5 我認為不需要有相關組織",
  "6 其他",
];

const q12Opts = [
  "1 公部門針對專科訓練一年辦理一次細節說明會",
  "2 公費生的專責考試輔導",
  "3 人事 人際溝通協調訓練",
  "4 有養成公費生的資訊交流平台",
  "5 和養成公費生學長姐有實際互動",
  "6 下鄉見習的機會",
  "7 其他",
];

const q13Opts = ["1 合適", "2 不合適"];
const q14Opts = [
  "1 回應很快也很有幫助",
  "2 回應很慢但很有幫助",
  "3 回應很快但沒有幫助",
  "4 回應很慢又沒有幫助",
  "5 近乎不理不睬",
  "6 沒有反映過",
];
const q15Opts = [
  "1 當地的生活機能",
  "2 當地與親密辦理職涯發展的有關資源",
  "3 當地的子女教養問題",
  "4 可否就近照顧家人",
  "5 服務單位內的主管特質和行政風格",
  "6 服務單位內的業務性質與工作量",
  "7 薪資和福利",
  "8 其他:",
];
const q16Opts = [
  "1 自己的專科會無法發揮 及 精進",
  "2 公衛保健的宣導更加重要",
  "3 業務包山包海、很繁雜",
  "4 沒 有其他專科同儕可以互動",
  "5 病人數偏少",
  "6 薪資福利等誘因較醫院來的低",
  "7 病人會對醫生或單位有不信任感",
  "8 完全不了解該執業環境",
  "9 其他:",
];
const q17Opts = [
  "1 需要輪班",
  "2 需要支援其他科別",
  "3 院內行政僵化，包括主管行政風格等",
  "4 病人數偏少",
  "5 沒有額外福利，例如服務年資加成計算、偏鄉加給等",
  "6 病人會對醫生或單位有不信任感",
  "7 完全不了解該執業環境",
  "8 其他:",
];
export default {
  data() {
    return {
      leftQuestions,
      q1: [],
      q1Opts,
      q1Other: "",
      q2: [],
      q2Opts,
      q2Other: "",
      q3: "",
      q3Opts,
      q4: [],
      q4Opts,
      q5: "",
      q5Opts,
      q6: "",
      q6Opts,
      q71: "",
      q72: "",
      q7Opts,
      q8: [],
      q8Opts,
      q8Other: "",
      q91: "",
      q92: "",
      q93: "",
      q10: [],
      q10Opts,
      q11: [],
      q11Opts,
      q11Other: "",
      q12: [],
      q12Opts,
      q12Other: "",
      q131: "",
      q132: "",
      q133: "",
      q134: "",
      q13Opts,
      q14: "",
      q14Opts,
      q15: [],
      q15Opts,
      q15Other: "",
      q16: [],
      q16Opts,
      q16Other: "",
      q17: [],
      q17Opts,
      q17Other: "",
      q18Other: "",
      id: "",
      year: "",
      closeBtn: false,
    };
  },
  props: ["allData"],
  methods: {
    updateItems(key) {
      requestAnimationFrame(() => {
        if (this[key].length < 4) return;
        this[key].splice(3);
      });
    },
    getFinalAnswer(answer, options) {
      let final = "";
      if (
        typeof answer == "string" ||
        typeof answer == "number" ||
        answer == null
      ) {
        final = options.map((s, i) => (answer == s ? "Y" : "N")).join(",");
      } else {
        final = options
          .map((s, i) => (answer.find((s2) => s2 == s) ? "Y" : "N"))
          .join(",");
      }
      return final;
    },
    async submitAnswer() {
      let RecordQ2 = {};
      RecordQ2.Question_01 = this.getFinalAnswer(this.q1, this.q1Opts);
      RecordQ2.Question_01_Other = this.q1Other;
      RecordQ2.Question_02 = this.getFinalAnswer(this.q2, this.q2Opts);
      RecordQ2.Question_02_Other = this.q2Other;
      RecordQ2.Question_03 = this.getFinalAnswer(this.q3, this.q3Opts);
      RecordQ2.Question_04 = this.getFinalAnswer(this.q4, this.q4Opts);
      RecordQ2.Question_05 = this.getFinalAnswer(this.q5, this.q5Opts);
      RecordQ2.Question_06 = this.getFinalAnswer(this.q6, this.q6Opts);
      RecordQ2.Question_071 = this.getFinalAnswer(this.q71, this.q7Opts);
      RecordQ2.Question_072 = this.getFinalAnswer(this.q72, this.q7Opts);
      RecordQ2.Question_08 = this.getFinalAnswer(this.q8, this.q8Opts);
      RecordQ2.Question_08_Other = this.q8Other;
      RecordQ2.Question_091 = this.getFinalAnswer(this.q91, this.q7Opts);
      RecordQ2.Question_092 = this.getFinalAnswer(this.q92, this.q7Opts);
      RecordQ2.Question_093 = this.getFinalAnswer(this.q93, this.q7Opts);
      RecordQ2.Question_10 = this.getFinalAnswer(this.q10, this.q10Opts);
      RecordQ2.Question_11 = this.getFinalAnswer(this.q11, this.q11Opts);
      RecordQ2.Question_11_Other = this.q11Other;
      RecordQ2.Question_12 = this.getFinalAnswer(this.q12, this.q12Opts);
      RecordQ2.Question_12_Other = this.q12Other;
      RecordQ2.Question_131 = this.getFinalAnswer(this.q131, this.q13Opts);
      RecordQ2.Question_132 = this.getFinalAnswer(this.q132, this.q13Opts);
      RecordQ2.Question_133 = this.getFinalAnswer(this.q133, this.q13Opts);
      RecordQ2.Question_134 = this.getFinalAnswer(this.q134, this.q13Opts);
      RecordQ2.Question_14 = this.getFinalAnswer(this.q14, this.q14Opts);
      RecordQ2.Question_15 = this.getFinalAnswer(this.q15, this.q15Opts);
      RecordQ2.Question_15_Other = this.q15Other;
      RecordQ2.Question_16 = this.getFinalAnswer(this.q16, this.q16Opts);
      RecordQ2.Question_16_Other = this.q16Other;
      RecordQ2.Question_17 = this.getFinalAnswer(this.q17, this.q17Opts);
      RecordQ2.Question_17_Other = this.q17Other;
      RecordQ2.Question_18 = this.q18Other;

      let obj = {};
      obj.RecordQ2 = RecordQ2;
      // obj.RecordBase = this.allData.RecordBase;
      obj.RecordBase = store.editItem.RecordBase;

      try {
        await window.axios.post("LocalRecord/SetAnswer", obj);
        this.$root.$emit("get-newestsurvey-data");
        this.$bvToast.toast(`儲存成功`, {
          title: "問卷調查",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        alert(e);
      }
    },
    getOriginalAnswer(final, options) {
      let originalAnswer = [];
      originalAnswer =
        (final &&
          final
            .split(",")
            .map((s, i) => (s == "Y" ? options[i] : ""))
            .filter((s) => s)) ||
        [];

      return originalAnswer;
    },
    fillForm() {
      // let string = "N,N,N,N,Y,N,N";
      // this.q3 = this.getOriginalAnswer(string, this.q3Opts);
      let items = { ...this.allData.RecordQ2 };
      this.q1 = this.getOriginalAnswer(items.Question_01, this.q1Opts);
      this.q1Other = items.Question_01_Other ? items.Question_01_Other : "";
      this.q2 = this.getOriginalAnswer(items.Question_02, this.q2Opts);
      this.q2Other = items.Question_02_Other ? items.Question_02_Other : "";
      this.q3 = this.getOriginalAnswer(items.Question_03, this.q3Opts)[0];
      this.q4 = this.getOriginalAnswer(items.Question_04, this.q4Opts);
      this.q5 = this.getOriginalAnswer(items.Question_05, this.q5Opts)[0];
      this.q6 = this.getOriginalAnswer(items.Question_06, this.q6Opts)[0];
      this.q71 = this.getOriginalAnswer(items.Question_071, this.q7Opts)[0];
      this.q72 = this.getOriginalAnswer(items.Question_072, this.q7Opts)[0];
      this.q8 = this.getOriginalAnswer(items.Question_08, this.q8Opts);
      this.q8Other = items.Question_08_Other ? items.Question_08_Other : "";
      this.q91 = this.getOriginalAnswer(items.Question_091, this.q7Opts)[0];
      this.q92 = this.getOriginalAnswer(items.Question_092, this.q7Opts)[0];
      this.q93 = this.getOriginalAnswer(items.Question_093, this.q7Opts)[0];
      this.q10 = this.getOriginalAnswer(items.Question_10, this.q10Opts);
      this.q11 = this.getOriginalAnswer(items.Question_11, this.q11Opts);
      this.q11Other = items.Question_11_Other ? items.Question_11_Other : "";
      this.q12 = this.getOriginalAnswer(items.Question_12, this.q12Opts);
      this.q12Other = items.Question_12_Other ? items.Question_12_Other : "";
      this.q131 = this.getOriginalAnswer(items.Question_131, this.q13Opts)[0];
      this.q132 = this.getOriginalAnswer(items.Question_132, this.q13Opts)[0];
      this.q133 = this.getOriginalAnswer(items.Question_133, this.q13Opts)[0];
      this.q134 = this.getOriginalAnswer(items.Question_134, this.q13Opts)[0];
      this.q14 = this.getOriginalAnswer(items.Question_14, this.q14Opts)[0];
      this.q15 = this.getOriginalAnswer(items.Question_15, this.q15Opts);
      this.q15Other = items.Question_15_Other ? items.Question_15_Other : "";
      this.q16 = this.getOriginalAnswer(items.Question_16, this.q16Opts);
      this.q16Other = items.Question_16_Other ? items.Question_16_Other : "";
      this.q17 = this.getOriginalAnswer(items.Question_17, this.q17Opts);
      this.q17Other = items.Question_17_Other ? items.Question_17_Other : "";
      this.q18Other = items.Question_18 ? items.Question_18 : "";
    },
  },
  mounted() {
    this.fillForm();
    this.closeBtn = store.editPerson ? store.editPerson.closeBtn : false;
    if (this.closeBtn) {
      document.querySelectorAll(".input-container input").forEach((e) => {
        e.disabled = true;
      });
    }
  },
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  max-width: 890px;
  margin: 30px auto;
  display: grid;
}
.dtc-2-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid black;
  border-top: none;
  > div {
    padding: 10px;
  }
  > div:first-child {
    border-right: 1px solid black;
    font-size: 16px;
  }
}
.dtc-2-rows {
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding: 0px !important;
  // border: 1px solid black;
  border-top: none;
  > div {
    padding: 8px;
  }
  > div:first-child {
    border-bottom: 1px solid black;
    // font-size: 16px;
  }
}
.dtc-3-rows {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  padding: 0px !important;
  // border: 1px solid black;
  border-top: none;
  > div {
    padding: 8px;
    border-bottom: 1px solid #eeeeee;
  }
  > div:last-child {
    border-bottom: 0px solid #eeeeee;
    // font-size: 16px;
  }
}
.dtc-4-rows {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  padding: 0px !important;
  // border: 1px solid black;
  border-top: none;
  > div {
    padding: 8px;
    border-bottom: 1px solid #eeeeee;
  }
  > div:last-child {
    border-bottom: 0px solid #999;
    // font-size: 16px;
  }
}
.header-dark {
  border: 1px solid black;
  line-height: 7px;
}
.number-div {
  display: grid;
  grid-template-columns: 10px 1fr;
  gap: 1rem;
}
</style>
